
<script setup>
import axios from 'axios';
import { ref,  onMounted, provide} from "vue";
import { mdStyle } from '../../static/dist/composable/styles/md.js' 
import TabNav from './components/TabNav.vue'
import TabNavMobile from './components/TabNavMobile.vue';

const url = ref('');
const resultObj = ref({});
const tabSelect = ref([])
const tabSelectTitle = ref('')
const isAdmin = ref(0);
const dropTabId = ref(0);
const action = ref('uebersicht')
const totalgroup = ref('0')
const styles = ref({});

provide('isAdmin', isAdmin);
provide('resultObj', resultObj);
provide('url', url);
provide('tabSelect', tabSelect);
provide('tabSelectTitle', tabSelectTitle);
provide('styles', styles);

onMounted(() => {
  styles.value    = mdStyle();
  url.value     = document.getElementById('nav_url').value;
  action.value  = document.getElementById('nav_action').value;
  totalgroup.value = document.getElementById('nav_totalgroup').value;

  if(url.value){
    getData();
  }

});

function getData(){

  let data = {};
  data.action = action.value;
  data.totalgroup = totalgroup.value;

  axios
      .post(url.value, data)
      .then((response) => {
          resultObj.value      = response.data;
          tabSelect.value      = response.data.tab_select_group_items;
          tabSelectTitle.value = response.data.tab_select_group_title;
          dropTabId.value      = response.data.dropTabId;
          //tabCssId.value       = response.data.tab_css_id;
          isAdmin.value        = response.data.isAdmin;
          isAdmin.value        = (isAdmin.value) ? true : false;
          //console.log(resultObj.value, isAdmin.value, dropTabId.value);
    })
}
</script>


<template>
  <div class="mdl-cell mdl-cell--12-col mdl-cell--hide-tablet mdl-cell--hide-phone own" style="margin-top:24px;">
    <TabNav v-model:dropTabId="dropTabId"/>
  </div>
  <div class="mdl-cell mdl-cell--12-col mdl-cell--hide-desktop own" style="margin-top:24px;">
    <TabNavMobile v-model:dropTabId="dropTabId"/>
  </div>
</template>

<style>
.iconCustom{

  font-size:10px;
}
</style>