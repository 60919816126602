import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios';
import VueAxious from 'vue-axios';

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import 'primeicons/primeicons.css'
//import 'primevue/resources/themes/aura-light-green/theme.css'
import "primevue/resources/primevue.min.css"; /* Deprecated */

//import 'bootstrap/dist/css/bootstrap.css';
import '../../static/css/custom.css'
import 'bootstrap/dist/js/bootstrap.bundle.js';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";


const app = createApp(App)
app.use(VueAxious, axios);
app.use(PrimeVue);
app.use(Button);
app.mount('#app_nav')