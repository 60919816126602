
export function mdStyle() {
    const ret = {
        gridRow: 'mdl-grid',
        gridCol12: 'mdl-cell mdl-cell--12-col',
        tHeader: 'mdl-data-table__cell tableHead borderBottom',
        tBodyFirst: 'tdContent borderLeftBottom borderRight',
        tBody: 'tdContent borderRight borderBottom',
        tBodyRes: 'tdContent borderBottom',
        tContent: 'tdContent',
        btnNormal: 'mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect',
        btnPrimary: 'mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--primary',
        trColors: ['row1', 'row2'],
        statsSelection: 'statsSelection',
        iconSave: 'pi pi-save iconCustom',
        iconEdit: 'pi pi-pen-to-square iconCustom',
        iconArchive: 'pi pi-file-export iconCustom',
        iconDelete: 'pi pi-trash iconCustom',
        iconClose: 'pi pi-times iconCustom',
        iconExport: 'pi pi-file-word iconCustom',
        iconEmpty: 'pi pi-times iconCustom',
        iconRight: 'pi pi-arrow-right iconCustom',
        iconLeft: 'pi pi-arrow-left iconCustom',
        iconAdd: 'pi pi-plus iconCustom',
        iconConfig: 'pi pi-cog iconCustom',
        iconHistory: 'pi pi-history iconCustom',
        iconAngelRight: 'pi pi-angle-right iconCustom',
        results:'results',
        borderEntries:'borderEntries'
      };
    return ret;
}